require('owl.carousel2')

$(document).ready(function(){
	$('.owl-carousel').owlCarousel({
	    loop:true,
	    margin:15,
	    autoplay:true,
	    dots:false,
	    dotsEach:2,
	    responsiveClass:true,
	    nav:true,
	    navText:['<i class="fas fa-angle-left"></i>','<i class="fas fa-angle-right"></i>'],
	    responsive:{
	        0:{
	            items:2
	        },
	        768:{
	            items:3
	        },
	        1200:{
	            items:3
	        },
	        1600:{
	            items:4
	        }
	    }
	})
});
